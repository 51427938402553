
$gray-0: white;
$gray-50: #eceff1;
$gray-100: #cfd8dc;
$gray-300: #90a4ae;
$gray-500: #607d8b;
$gray-700: #455a64;
$gray-900: #263238;
$gray-1000: black;

$primary: #1b4d3e;
$light: #23a076;
$dark: #0c241c;
