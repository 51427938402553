@use "col";

@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@0,400;0,700;1,400;1,700&family=Roboto+Mono:ital,wght@0,400;0,700;1,400;1,700&display=swap');


$main-font: 'Noto Sans', sans-serif;
$mono-font: 'Roboto Mono', monospace;
$mobile-width: 1000px;


$pad: 24px;

body {
  margin: 0;
  font-family: $main-font;
  font-size: 16px;
  color: col.$gray-1000;
  line-height: 150%;
}

a {
  text-decoration: none;
  border-bottom: 1px solid col.$gray-500;
  color: col.$gray-1000;

  &:hover, &:enabled {
    color: col.$gray-500;
    cursor: pointer;
  }
}

.a-no-underline {
  border-bottom: none;
}

hr {
  height: 1px;
  background-image: linear-gradient(to right, rgba(52, 52, 52, 0.5) 60%, rgba(255,255,255,0) 0%);
  background-position: bottom;
  background-size: 10px 1px;
  background-repeat: repeat-x;
  border: none;
}

.app {
  margin: $pad;

  @media screen and (max-width: $mobile-width) {
    margin: $pad*.6;
    margin-top: $pad*.7;
  }
}

.p {
  margin-top: $pad;
  margin-bottom: $pad;
}

.layout {
  display: grid;
  grid-template-columns: 1fr 2fr;
  gap: $pad;

  @media screen and (max-width: $mobile-width) {
    grid-template-columns: 1fr;
  }
}

.left-right {
  display: flex;
  gap: $pad;

  @media screen and (max-width: $mobile-width) {
    flex-direction: column;
  }
}

.left-right-always {
  display: flex;
  gap: $pad;
}

.header {
  padding-bottom: $pad*.5;
  color: col.$gray-500;
}

.bold {
  font-weight: bold;
}

.footer {
  margin-top: $pad;
  color: col.$gray-500;
  font-size: 0.5rem;
}

.profile-img {
  width: 130px;

  @media screen and (max-width: $mobile-width) {
    width: 100px;
  }
}

.projects {
  margin-top: $pad;
  margin-bottom: $pad;
  display: flex;
  flex-direction: column;
  gap: $pad;
}

.project-img {
  width: 300px;

  @media screen and (max-width: $mobile-width) {
    width: 100%;
  }
}

.project-title {
  font-weight: bold;
  font-style: italic;
}

.project-description {
  margin-top: $pad*.5;
  margin-bottom: $pad*.5;
  color: col.$gray-700;
}

